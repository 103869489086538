<template>
  <!-- 员工管理 -->
  <div class="page-container">
    <div class="page-top-tool">
      <el-button v-if="userPrower == '1'" type="success" icon="el-icon-plus" size="mini" @click="toAddEmployeePage">新增员工</el-button>
      <div class="right-search">
        <span>所属地区：</span>
        <el-select v-model="area" clearable filterable :disabled="empType != '1' || loading" placeholder="请选择" size="mini" style="width: 160px;">
          <el-option
            v-for="item in areaList"
            :key="item.id"
            :label="item.name"
            :value="item.name">
          </el-option>
        </el-select>
        <span style="margin-left: 20px;">团队经理：</span>
        <el-select v-model="fcode" placeholder="请选择" :disabled="loading" filterable clearable size="mini" style="width: 180px;">
          <el-option
            v-for="item in fnameOptions"
            :key="item.empcode"
            :label="`${item.empname} ${item.emparea ? '（'+item.emparea+'）' : ''}`"
            :value="item.empcode">
          </el-option>
        </el-select>
        <span style="margin-left: 20px;">员工姓名：</span>
        <el-input v-model="empname" placeholder="请输入" :disabled="loading" clearable size="mini" style="width: 230px;"></el-input>
        <el-button type="primary" icon="el-icon-search" size="mini" :disabled="loading" style="margin: 0px 20px;" @click="onSearch">搜索</el-button>
        
      </div>
    </div>
    <el-radio-group v-model="empType" size="mini" :disabled="loading" style="margin-top: 10px;">
        <el-radio-button label="1">所有员工</el-radio-button>
        <el-radio-button label="2">只看团队经理</el-radio-button>
        <el-radio-button label="3">只看规划师</el-radio-button>
        <el-radio-button label="4">离职员工</el-radio-button>
    </el-radio-group>
    <el-table
      v-loading="loading"
      element-loading-text="数据加载中"
      element-loading-spinner="el-icon-loading"
      :data="tableData"
      style="width: 100%; font-size: 12px; margin: 10px 0px;"
      :header-cell-style="tableHeaderColor"
    >
      <el-table-column prop="empcode" label="员工编号" width="140">
      </el-table-column>
      <el-table-column label="员工姓名" width="130">
        <template slot-scope="scope">
          <div>
            {{ scope.row.empname }}
            <el-tag v-if="scope.row.state == '2'" type="warning" size="mini">离职</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="phone" label="手机号" width="110">
      </el-table-column>
      <el-table-column prop="role" label="职级" width="90">
        <template slot-scope="scope">
          <span>{{ scope.row.role == '1' ? '团队经理' : '规划师' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="收货地址" prop="address"></el-table-column>
      <el-table-column prop="fname" label="所属团队" width="80">
      </el-table-column>
      <el-table-column prop="area" label="所属地区" width="120">
      </el-table-column>
      <el-table-column label="操作" width="280">
        <template slot-scope="scope">
          <el-button v-if="scope.row.state == '1'" type="text" size="mini" @click="showStore(scope)">查看网点</el-button>
          <el-button v-if="userPrower == '1' && scope.row.state == '1'" type="text" size="mini" @click="bindStore(scope)">新增网点</el-button>
          <el-button v-if="userPrower == '1' && scope.row.state == '1'" type="text" size="mini" @click="showBindStorePanel(scope)">解绑网点</el-button>
          <el-button v-if="userPrower == '1' && scope.row.state == '1'" type="text" size="mini" @click="onUpdate(scope)">编辑</el-button>
          <el-button v-if="userPrower == '1' && scope.row.state == '1'" type="text" size="mini" @click="onOffWork(scope)">离职</el-button>
          <el-button v-if="userPrower == '1' && scope.row.state == '2'" type="text" size="mini" @click="onDelete(scope)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <el-pagination
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage"
      :page-size="pageSize"
      hide-on-single-page
      background
      layout="total, prev, pager, next"
      :total="total">
    </el-pagination>

    <!-- 查看网点弹框 -->
    <el-dialog title="查看网点" :visible.sync="dialogStoreTableVisible" @closed="dialogClosed">
      <div class="store-table-dialog">
        <el-table :data="storeTableData">
          <el-table-column property="storecode" label="网点编号" width="150"></el-table-column>
          <el-table-column property="storename" label="网点名称"></el-table-column>
          <el-table-column property="address" label="网点地址"></el-table-column>
        </el-table>
      </div>
    </el-dialog>

    <!-- 解绑网点弹框 -->
    <el-dialog title="已绑定网点" :visible.sync="dialogUnbindStoreTableVisible" :close-on-click-modal="false" @closed="dialogClosed">
      <div class="store-table-dialog">
        <!-- <div class="select-store-dialog">
          <el-transfer
            filterable
            :filter-method="storeFilterMethod"
            filter-placeholder="请输入"
            :titles="['可选网点', '已选网点']"
            v-model="selectStoreValue"
            :data="storeAllList">
          </el-transfer>
        </div> -->
        <el-checkbox-group v-model="checkStoreKeys">
          <el-checkbox v-for="item in selectStoreValue" :key="item.id" :label="item.id">{{ item.storename }}</el-checkbox>
        </el-checkbox-group>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" @click="onCancelStoreSelect">取 消</el-button>
        <el-button type="primary" size="mini" :loading="unBindStoreSubmitLoading" @click="onStoreSelectedSubmit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'HuijiliWebIndex',

  data() {
    return {
      userPrower: '', // 用户权限
      area: '', // 所属地区
      fcode: '', // 所属团队
      empname: '', // 员工姓名
      empType: '1', // 角色
      fnameOptions: [], // 团队
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      loading: false,
      areaList: [],
      allStoreList: [],
      allEmployeeList: [],
      dialogStoreTableVisible: false, // 查看网点弹框状态
      storeTableData: [], // 查看网点数据
      selectStoreValue: [], // 原始选择的网点
      checkStoreKeys: [], // 已选泽的网点
      storeAllList: [], // 过滤后的所有可选网点
      dialogUnbindStoreTableVisible: false, // 解绑网点弹框状态
      // 网点选择穿梭框搜索事件
      storeFilterMethod(query, item) {
        return item.label.includes(query)
      },
      unBindStoreRow: null, // 要解绑的员工对象
      unBindStoreSubmitLoading: false, // 解绑确定按钮加载
    };
  },
  watch: {
    empType() {
      this.currentPage = 1
      this.getData()
    },
    allStoreList: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val.length > 0) {
          this.fnameOptions.forEach(f => {
            val.forEach(store => {
              if (f.empcode == store.fcode) {
                f.emparea = store.area
              }
            })
          })
        }
      }
    },
  },
  mounted() {
    let userInfo = localStorage.getItem('userInfo')
    if (userInfo) {
      userInfo = JSON.parse(userInfo)
      this.userPrower = userInfo.prower
    } else {
      this.$http({
        method: 'post',
        url: 'user_exit'
      })
      this.$router.push({
        path: '/login'
      })
    }
    this.queryAllData()
  },

  methods: {
    async queryAllData() {
      this.loading = true
      const storeResp = await this.$http({
        method: 'post',
        url: 'store_findall'
      })
      const empResp = await this.$http({
        method: 'post',
        url: 'employee_findall'
      })
      const areaResp = await this.$http({
        method: 'post',
        url: 'area_findall'
      })
      if (storeResp.errno == 0) {
        this.allStoreList = storeResp.data.data
        sessionStorage.setItem('allStoreList', JSON.stringify(this.allStoreList))
        this.getData()
      } else {
        this.$message.error('网点加载失败')
      }
      if (empResp.errno == 0) {
        this.allEmployeeList = empResp.data.data
        // 查询所有团队经理
        this.fnameOptions = empResp.data.data.filter(item => {
          return item.role == '1'
        })
        this.allEmployeeList.forEach(item => {
          for(let i = 0; i < this.allStoreList.length; i++) {
            const store = this.allStoreList[i]
            if (item.role == '2' && !item.fcode) {
              if (store.scode && store.scode == item.empcode) {
                item.fname = store.fname
                item.fcode = store.fcode
                break
              }
            }
          }
        })
        this.allEmployeeList.forEach(item => {
          this.allStoreList.forEach(store => {
            if (store.scode == item.empcode || store.fcode == item.empcode) {
              item.area = store.area
            }
          })
        })
        sessionStorage.setItem('allEmployeeList', JSON.stringify(this.allEmployeeList))
      } else {
        this.$message.error('员工加载失败')
      }
      if (areaResp.errno == 0) {
        this.areaList = areaResp.data.data
      } else {
        this.$message.error('地区加载失败')
      }

      this.employeeOptions = this.allEmployeeList.filter(item => {
        return item.role == '1'
      })
    },
    getData() {
      const params = {
          empname: this.empname,
          fcode: this.fcode,
          // state: '1',
          page: this.currentPage,
          pageSize: this.pageSize
      }
      if (this.empType == '2') {
        params.role = '1'
      } else if (this.empType == '3') {
        params.role = '2'
      } else if (this.empType == '4') {
        params.state = '2'
      }
      // this.loading = true
      this.$http({
        method: 'post',
        url: 'employee_find',
        params
      })
      .then(res => {
        this.loading = false
        if (res.errno  == 0) {
          const { data, page, pageSize, count} = res.data
          // this.tableData = data
          this.currentPage = page
          this.pageSize = pageSize
          this.total = count
          this.tableData = data
          this.tableData.forEach(item => {
            for(let i = 0; i < this.allStoreList.length; i++) {
              const store = this.allStoreList[i]
              if (item.role == '2' && !item.fcode) {
                if (store.scode && store.scode == item.empcode) {
                  item.fname = store.fname
                  item.fcode = store.fcode
                  break
                }
              }
            }
          })

          this.tableData.forEach(item => {
            this.allStoreList.forEach(store => {
              if (store.scode == item.empcode || store.fcode == item.empcode) {
                item.area = store.area
              }
            })
          })
        } else {
          this.$message.error('员工数据查询失败')
        }
      })
      .catch(err => {
        this.loading = false
        this.$message.error('员工数据查询失败')
      })
    },
    toAddEmployeePage() {
      this.$router.push({
        path: '/addEmployee'
      })
    },
    tableHeaderColor({row, column, rowIndex, columnIndex}) {
      return 'background-color: #F2F2F2;color:#101010;'
    },
    onSearch() {
      this.currentPage = 1

      if (this.area || this.fcode || this.empname) {
        this.handleSearchQuery()
      } else {
        this.getData()
      }
    },
    async handleSearchQuery() {
      this.loading = true
      this.tableData = this.allEmployeeList
      if (this.area) {
        this.tableData = this.tableData.filter(emp => {
          return emp.area == this.area
        })
      }
      if (this.fcode) {
        this.tableData = this.tableData.filter(emp => {
          return emp.fcode == this.fcode
        })
      }
      if (this.empname) {
        this.tableData = this.tableData.filter(emp => {
          return emp.empname.includes(this.empname)
        })
      }
      this.total = 0
      this.currentPage = 1
      this.loading = false
    },
    handleCurrentChange(page) {
      this.currentPage = page
      this.getData()
    },
    // 查看网点
    async showStore({ row, $index }) {
      if (!row.empcode) {
        this.$message.warning('员工编号有误！')
        return
      }
      this.dialogStoreTableVisible = true
      const params = {}
      if (row.role == '1') {
        params.fcode = row.empcode
      } else if (row.role == '2') {
        params.scode = row.empcode
      }
      const resp = await this.$http({
        method: 'post',
        url: 'store_findall',
        params
      })
      if (resp.errno == 0) {
        this.storeTableData = resp.data.data
      } else {
        this.$message.error('查询失败')
      }
    },
    // 显示解绑网点面板
    async showBindStorePanel({ row, $index }) {
      this.selectStoreValue = []
      this.storeAllList = []
      this.checkStoreKeys = []
      this.loading = true
      this.unBindStoreRow = row
      const ersp = await this.$http({
        method: 'post',
        url: 'store_findall'
      })
      if (ersp.errno != 0) {
        this.$message.error('网点查询失败')
        this.loading = false
        return
      }
      const stores = ersp.data.data
      if (row.role == '1') {
        // 设置经理网点
        this.selectStoreValue = stores.filter(item => {
          return item.fcode == row.empcode
        })
        this.checkStoreKeys = this.selectStoreValue.map(item => {
          return item.id
        })
      } else if (row.role == '2') {
        // 设置规划师网点
        this.selectStoreValue = stores.filter(item => {
          return item.scode == row.empcode
        })
        this.checkStoreKeys = this.selectStoreValue.map(item => {
          return item.id
        })
      }
      this.dialogUnbindStoreTableVisible = true
      this.loading = false
    },
    // 解绑网点
    unBindStore({ row, $index }) {
      this.$confirm(`确定要解绑 ${row.empname} 负责的所有网点吗？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
      }).then(() => {
          this.onOffsetStore(row)
      }).catch(() => {});
    },
    // 设置网点
    bindStore(scope) {
      sessionStorage.setItem('updateEmployee', JSON.stringify(scope.row))
      this.$router.push({
        path: '/setStore',
        query: {
          id: scope.row.id
        }
      })
    },
    // 确定网点选择
    async onStoreSelectedSubmit() {
      this.unBindStoreSubmitLoading = true
      const storelist = []
      const tempList = this.selectStoreValue.filter(item => {
        return !this.checkStoreKeys.includes(item.id)
      })
      tempList.forEach(item => {
        if (this.unBindStoreRow.role == '1') {
          storelist.push({
            id: item.id,
            fcode: 'null',
            fname: 'null'
          })
        } else if (this.unBindStoreRow.role == '2') {
          storelist.push({
            id: item.id,
            scode: 'null',
            sname: 'null'
          })
        }
      })

      const resp = await this.$http({
        method: 'post',
        url: 'store_updatebth',
        params: {
          storelist: JSON.stringify(storelist)
        }
      })
      if (resp.data) {
        this.unBindStoreSubmitLoading = false
        this.dialogUnbindStoreTableVisible = false
        let resCount = 0
        resp.data.forEach(item => {
          if (item.errno == 0) {
            resCount++
          }
        })
        this.$message.success(`已解绑 ${resCount} 个网点`)
      } else {
        this.$message.error('解绑失败')
        this.unBindStoreSubmitLoading = false
      }
    },
    // 取消网点选择
    onCancelStoreSelect() {
      this.dialogUnbindStoreTableVisible = false
    },
    onUpdate(scope) {
      sessionStorage.setItem('updateEmployee', JSON.stringify(scope.row))
      this.$router.push({
        path: '/updateEmployee',
        query: {
          id: scope.row.id
        }
      })
    },
    // 离职
    onOffWork({ row, $index }) {
      this.$confirm(`设置 ${row.empname} 离职后将自动解绑所有网点，是否继续？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.loading = true
          this.$http({
            method: 'post',
            url: 'employee_update',
            params: {
              id: row.id,
              state: '2'
            }
          })
          .then(res => {
            this.loading = false
            if (res.errno == 0) {
              this.$message.success('离职设置成功')
              // this.getData()
              this.onOffsetStore(row)
            } else {
              this.$message.error('服务端异常，网点删除失败')
            }
          })
          .catch(err => {
            this.loading = false
            this.$message.error('服务端异常，网点删除失败')
          })
        }).catch(() => {});
    },
    // 解绑员工网点
    onOffsetStore(row) {
      // const checkStoreList = this.allStoreList.filter(item => {
      //   if (row.role == '1') {
      //     return item.fcode == row.empcode
      //   } else {
      //     return item.scode == row.empcode
      //   }
      // })
      this.loading = true
      const storelist = []
      this.allStoreList.forEach(item => {
        if (row.role == '1' && row.empcode == item.fcode) {
          storelist.push({
            id: item.id,
            fcode: 'null',
            fname: 'null'
          })
        } else if (row.role == '2' && row.empcode == item.scode) {
          storelist.push({
            id: item.id,
            scode: 'null',
            sname: 'null'
          })
        }
      })

      this.$http({
        method: 'post',
        url: 'store_updatebth',
        params: {
          storelist: JSON.stringify(storelist)
        }
      })
      .then(res => {
        this.loading = false
        if (res.data) {
            let resCount = 0
            res.data.forEach(item => {
                if (item.errno == 0) {
                  resCount++
                }
            })
            this.$message.success(`已解绑 ${resCount} 个网点`)
            this.queryAllData()
            this.getData()
        } else {
          this.$message.error('网络异常，操作失败')
        }
      })
      .catch(err => {
        this.loading = false
        this.$message.error('网络异常，操作失败')
      })
    },
    onDelete(scope) {
      this.$confirm(`确定要删除 ${scope.row.empname} 吗？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http({
            method: 'post',
            url: 'employee_delete',
            params: {
              id: scope.row.id
            }
          })
          .then(res => {
            if (res.errno == 0) {
              this.$message.success('删除成功')
              this.getData()
            } else {
              this.$message.error('服务端异常，网点删除失败')
            }
          })
          .catch(err => {
            this.$message.error('服务端异常，网点删除失败')
          })
        }).catch(() => {});
    },
    dialogClosed() {
      this.storeTableData = []
      this.checkStoreKeys = []
      this.selectStoreValue = []
    }
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  background: #fff;
  height: 100%;
  width: 100%;
  border-radius: 3px;
  box-sizing: border-box;
  padding: 20px 25px;
  overflow-y: scroll;

  .page-top-tool {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
  }
}

/* 修改滚动条样式 */
.page-container::-webkit-scrollbar {
	width: 4px;
}
.page-container::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
	background: rgba(0, 0, 0, 0.2);
}

::v-deep .el-dialog {
  width: 600px;
  height: 500px;
  top: -20px;
}
.store-table-dialog {
  height: 320px;
  overflow-y: scroll;

  .el-checkbox-group {
    display: flex;
    flex-direction: column;
    margin: 0px 30px;

    .el-checkbox {
      margin: 10px 0px;
    }
  }
}
/* 修改滚动条样式 */
.store-table-dialog::-webkit-scrollbar {
	width: 4px;
}
.store-table-dialog::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
	background: rgba(0, 0, 0, 0.2);
}

.select-store-dialog {
  // text-align: center;
  padding-left: 50px;
  height: 400px;
}
.dialog-footer{
  position: absolute;
  // bottom: 50px;
  right: 50px;
}
.el-transfer {
  width: 100%;
  height: 400px;
}
::v-deep .el-transfer-panel {
    width: 40%;
}
::v-deep .el-checkbox-group.el-transfer-panel__list.is-filterable{
  height: 300px;
}
::v-deep .el-transfer-panel {
  height: 400px;
}
</style>